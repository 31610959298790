// 状态
const state = {
  // 登陆后的状态
  token: window.localStorage.getItem('token') || '',
};
// 修改状态
const mutations = {
  // 设置token
  setToken(state, token) {
    state.token = token;
    // 持久化保存
    window.localStorage.setItem('token', token);
  },
  // 删除token
  removeToken(state) {
    state.token = null;
    window.localStorage.removeItem('token');
  },
};
// 异步
const actions = {

};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
