import Vue from 'vue';
import Vuex from 'vuex';
import getters from './getters';
import users from './modules/user';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    users,
  },
  getters,
});
