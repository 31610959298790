import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './styles/common.scss'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'amfe-flexible'
import VueClipboard from 'vue-clipboard2'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'


const utilsDir = require.context('./utils', false, /^.*\.js$/)

utilsDir.keys().forEach((fileName) => {
  const name = fileName.replace(/^\.\/(.*)\.\w+$/, '$1')
  if (name === 'routeUtils') return
  const variable = '$' + name
  import('@/utils/' + name).then((exportObj) => { Vue.prototype[variable] = exportObj.default })
})

Vue.use(VueClipboard)

Vue.config.productionTip = false
Vue.use(ElementUI)



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
