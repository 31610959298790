import axios from 'axios';

const url = '/jst_website';

const entity = axios.create({
  baseURL: url,
  timeout: 5000,
});

function send(suffix, params, method) {
  // console.log(entity)
  return new Promise((resolve, reject) => {
    console.log(`向${suffix}发送的数据:  `, params);
    let promise = null;
    switch (method) {
      case 'GET':
        promise = entity.get(suffix, params);
        break;
      default:
        promise = entity.post(suffix, params);
        break;
    }
    try {
      promise.then((res) => {
        resolve(res);
        console.log(`${suffix} 返回的数据: `, res);
      });
    } catch (error) {
      reject(error);
    }
  });
}

// 请求拦截
entity.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    // config.headers['HG-Token'] = token
  }
  return config;
}, (err) => Promise.reject(err));

// 响应拦截
entity.interceptors.response.use((res) => res.data, (err) => Promise.reject(err));

const host = 'http://www.syjilai.com/jst_website';

function getHost() {
  return host;
}

export default
{
  send,
  getHost,
};
